import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Rhythm } from 'components/Rhythm';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Shade } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Shade" title="Shade" mdxType="PageTitle" />
    <h2 {...{
      "id": "primary-shade"
    }}>{`Primary shade`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <Shade color=\"primary\">\n    <Rhythm p={4}>Hello world</Rhythm>\n  </Shade>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Shade,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Shade color="primary" mdxType="Shade">
      <Rhythm p={4} mdxType="Rhythm">Hello world</Rhythm>
    </Shade>
  </ThemeWrapper>
    </Playground>
    <div style={{
      height: 50
    }} />
    <Playground __position={3} __code={'<ThemeWrapper>\n  <Shade opaque color=\"primary\">\n    <Rhythm p={4}>Hello world, opaque</Rhythm>\n  </Shade>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Shade,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Shade opaque color="primary" mdxType="Shade">
      <Rhythm p={4} mdxType="Rhythm">Hello world, opaque</Rhythm>
    </Shade>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "success-shade"
    }}>{`Success shade`}</h2>
    <Playground __position={4} __code={'<ThemeWrapper>\n  <Shade color=\"success\">\n    <Rhythm p={4}>Hello world</Rhythm>\n  </Shade>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Shade,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Shade color="success" mdxType="Shade">
      <Rhythm p={4} mdxType="Rhythm">Hello world</Rhythm>
    </Shade>
  </ThemeWrapper>
    </Playground>
    <div style={{
      height: 50
    }} />
    <Playground __position={6} __code={'<ThemeWrapper>\n  <Shade opaque color=\"success\">\n    <Rhythm p={4}>Hello world opaque</Rhythm>\n  </Shade>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Shade,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Shade opaque color="success" mdxType="Shade">
      <Rhythm p={4} mdxType="Rhythm">Hello world opaque</Rhythm>
    </Shade>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "warning-shade"
    }}>{`Warning shade`}</h2>
    <Playground __position={7} __code={'<ThemeWrapper>\n  <Shade color=\"warning\">\n    <Rhythm p={4}>Hello world</Rhythm>\n  </Shade>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Shade,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Shade color="warning" mdxType="Shade">
      <Rhythm p={4} mdxType="Rhythm">Hello world</Rhythm>
    </Shade>
  </ThemeWrapper>
    </Playground>
    <div style={{
      height: 50
    }} />
    <Playground __position={9} __code={'<ThemeWrapper>\n  <Shade opaque color=\"warning\">\n    <Rhythm p={4}>Hello world opaque</Rhythm>\n  </Shade>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Shade,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Shade opaque color="warning" mdxType="Shade">
      <Rhythm p={4} mdxType="Rhythm">Hello world opaque</Rhythm>
    </Shade>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "danger-shade"
    }}>{`Danger shade`}</h2>
    <Playground __position={10} __code={'<ThemeWrapper>\n  <Shade color=\"danger\">\n    <Rhythm p={4}>Hello world</Rhythm>\n  </Shade>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Shade,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Shade color="danger" mdxType="Shade">
      <Rhythm p={4} mdxType="Rhythm">Hello world</Rhythm>
    </Shade>
  </ThemeWrapper>
    </Playground>
    <div style={{
      height: 50
    }} />
    <Playground __position={12} __code={'<ThemeWrapper>\n  <Shade opaque color=\"danger\">\n    <Rhythm p={4}>Hello world, opaque</Rhythm>\n  </Shade>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Shade,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Shade opaque color="danger" mdxType="Shade">
      <Rhythm p={4} mdxType="Rhythm">Hello world, opaque</Rhythm>
    </Shade>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "neutral-shade"
    }}>{`Neutral shade`}</h2>
    <Playground __position={13} __code={'<ThemeWrapper>\n  <Shade color=\"neutral\">\n    <Rhythm p={4}>Hello world</Rhythm>\n  </Shade>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Shade,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Shade color="neutral" mdxType="Shade">
      <Rhythm p={4} mdxType="Rhythm">Hello world</Rhythm>
    </Shade>
  </ThemeWrapper>
    </Playground>
    <div style={{
      height: 50
    }} />
    <Playground __position={15} __code={'<ThemeWrapper>\n  <Shade opaque color=\"neutral\">\n    <Rhythm p={4}>Hello world, opaque</Rhythm>\n  </Shade>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Shade,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Shade opaque color="neutral" mdxType="Shade">
      <Rhythm p={4} mdxType="Rhythm">Hello world, opaque</Rhythm>
    </Shade>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <Props of={Shade} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      